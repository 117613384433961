.on-hover-display-child {
  .hover-child {
    display: none;
  }

  &:hover {
    .hover-child {
      display: inline;
    }
  }
}

.on-hover-visible-child {
  .hover-child {
    visibility: hidden;
  }

  &:hover {
    .hover-child {
      visibility: visible;
    }
  }
}
